import { AnchorHTMLAttributes } from 'react';
import Spinner from '../../components/LoadingScreens/Spinner';
import { cn } from '../../lib/classNames';

type LinkButtonProps = {
  loading?: boolean;
  className?: string;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const LinkButton = ({
  loading,
  children,
  className,
  ...props
}: LinkButtonProps) => {
  return (
    <a
      type="a"
      className={cn(
        'rounded-full bg-peach-50 px-3.5 py-2 text-base font-medium text-grey-90 outline-none',
        'hover:bg-peach-60 focus:ring-1 focus:ring-grey-90',
        'disabled:bg-grey-10 disabled:text-muted',
        className,
      )}
      {...props}
    >
      {loading ? <Spinner size="w-5" color="text-grey-90" /> : children}
    </a>
  );
};

export default LinkButton;
